<template>
  <div class="publishing">
    <pvp-btn v-if="canPublish" @click="fetchTournamentPublish">
      <template v-if="status === 'not_sended'">
        {{ $t('tournaments.publish') }}
      </template>
      <template v-else>
        {{ $t('tournaments.republish') }}
      </template>
    </pvp-btn>
    <div v-if="status === 'rejected'" class="rejected">
      {{ $t('tournaments.publish_rejected') }}
      <pvp-tooltip>
        <icon name="info" />
        <template #tooltip>
          {{ form.publishing.rejectReason }}
        </template>
      </pvp-tooltip>
    </div>
    <InfoBox
      v-if="publishInfo.variant"
      :variant="publishInfo.variant"
    >
      <template v-for="error in publishInfo.content">{{
        error
      }}</template>
    </InfoBox>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'TournamentPublishing',
  components: {
    Icon,
    InfoBox,
  },
  data: () => ({
    publishInfo: {},
  }),
  computed: {
    ...mapState('tournamentSettings', ['form', 'abilities']),
    status() {
      return this.form.publishing.status;
    },
    tnId() {
      return this.$route.params.tnId;
    },
    canPublish() {
      const isValideStatus = ['not_sended', 'rejected'].includes(
        this.status,
      );
      return (
        isValideStatus && this.abilities?.canServiceRequestPublish
      );
    },
  },
  created() {
    this.checkInfobox();
  },
  methods: {
    ...mapActions('tournamentSettings', ['fetchTournamentFields']),

    fetchTournamentPublish() {
      this.publishInfo = {};
      return api
        .patch(`/tournament/${this.tnId}/visibility/publish`)
        .catch(({ error }) => {
          this.publishInfo = {
            variant: 'error',
            content: error,
          };
        })
        .finally(() => {
          this.fetchTournamentFields(this.tnId).finally(
            this.checkInfobox,
          );
        });
    },

    checkInfobox() {
      switch (this.status) {
        case 'sended':
          this.publishInfo = {
            variant: 'info',
            content: this.$t('tournaments.publish_waiting'),
          };
          break;
        case 'confirmed':
          this.publishInfo = {
            variant: 'success',
            content: this.$t('save.publish_success'),
          };
          break;
        default:
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rejected {
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: $orangey-red;
  font-size: 15px;
  @include min-laptop() {
    justify-content: flex-end;
  }
  @include max-laptop() {
    justify-content: center;
  }

  .icon {
    margin-left: 4px;
    color: rgba(white, 0.5);
    font-size: 20px;
  }
}

.box-info {
  background-color: rgba(black, 0.1);
}
</style>
